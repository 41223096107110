<template>
  <div class="section-wrapper">
    <el-upload
      action=""
      :http-request="request"
      :on-remove="handleRemove"
      :on-change="change"
      list-type="picture-card"
      :auto-upload="true"
      :file-list="upload.fileList"
      accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
      :multiple="true"
      :before-upload="beforeUpload"
      :limit="limit"
    >
      <template #default>
        <i class="el-icon-plus"></i>
      </template>
      <template #tip>
        <div class="el-upload__tip">
          <span style="color: red">单个图片大小不能超过6M</span>
        </div>
      </template>
      <template #file="{ file }">
        <div style="height: 100%">
          <template
            v-if="
              (file.name && file.name.toLowerCase().endsWith('.pdf')) ||
              file.url.endsWith('.pdf')
            "
          >
            <img
              class="el-upload-list__item-thumbnail"
              src="https://pc.nnfwzl.com/rent-house/rent-platform-web/pdf.png"
              alt=""
            />
          </template>
          <template v-else>
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
          </template>
          <span class="el-upload-list__item-actions">
            <!-- <template v-if="file.name && !file.name.endsWith('.pdf')">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <el-icon :size="20"><zoom-in /></el-icon>
              </span>
            </template> -->
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <el-icon :size="20"><zoom-in /></el-icon>
            </span>
            <span
              v-if="!upload.disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <el-icon :size="20"><Delete /></el-icon>
            </span>
          </span>
        </div>
      </template>
    </el-upload>
    <el-dialog v-model="upload.dialogVisible">
      <img
        style="width: 100%; height: 450px"
        :src="upload.dialogImageUrl"
        alt=""
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, watch, defineProps, defineEmits, ref } from 'vue'
import { uploadFile } from '@/api/sys'
import { compressImg } from '@/utils/compressImg'
import { ElMessage } from 'element-plus'

const props = defineProps({
  type: Number, // 某种业务类型的标识
  list: Array,
  limit: Number
})
const emits = defineEmits(['deleteFile', 'handleFile'])

const upload = reactive({
  dialogImageUrl: '',
  dialogVisible: false,
  disabled: false,
  fileList: props.list || []
})
const handleRemove = (file) => {
  upload.fileList = upload.fileList.filter((item) => file.uid !== item.uid)
  emits('deleteFile', file)
}
const handlePictureCardPreview = (file) => {
  if (
    (file.name && file.name.toLowerCase().includes('.pdf')) ||
    file.url.endsWith('.pdf')
  ) {
    window.open(file.url, '_blank')
    return
  }
  upload.dialogImageUrl = file.url
  upload.dialogVisible = true
}
const change = (file, fileList) => {
  upload.fileList = fileList
}
const request = async (option) => {
  const formData = new FormData()
  const item = upload.fileList[upload.fileList.length - 1]
  formData.append('file', option.file)
  const resp = await uploadFile(formData)
  resp.uid = option.file.uid
  resp.name = option.file.name
  resp.url = item.url
  emits('handleFile', resp)
}

const beforeUpload = (file) => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (file.size > 6 * 1024 * 1024) {
      ElMessage.error('上传图片或者PDF格式的材料要小于6M')
      reject(new Error('上传图片或者PDF格式的材料要小于6M'))
      return
    }
    if (file.type.includes('image')) {
      const newFile = await compressImg(file)
      resolve(newFile)
    } else if (file.type.includes('pdf')) {
      resolve(file)
    } else {
      ElMessage.error('请上传图片或者PDF格式的材料')
      reject(new Error('请上传图片或者PDF格式的材料'))
    }
  })
}

watch(
  () => props.list,
  (newVal) => {
    upload.fileList = newVal
  }
)
</script>

<style scoped lang="scss">
::v-deep .el-upload-dragger {
  height: 150px;
  width: 150px;
}
// ::v-deep .el-upload--picture-card {
//   border: none;
// }
</style>
